exports.components = {
  "component---src-pages-newsletter-besten-dank-tsx": () => import("./../../../src/pages/newsletter/besten-dank.tsx" /* webpackChunkName: "component---src-pages-newsletter-besten-dank-tsx" */),
  "component---src-pages-newsletter-tsx": () => import("./../../../src/pages/newsletter.tsx" /* webpackChunkName: "component---src-pages-newsletter-tsx" */),
  "component---src-pages-newsroom-forschung-tsx": () => import("./../../../src/pages/newsroom/forschung.tsx" /* webpackChunkName: "component---src-pages-newsroom-forschung-tsx" */),
  "component---src-pages-newsroom-medienmitteilungen-tsx": () => import("./../../../src/pages/newsroom/medienmitteilungen.tsx" /* webpackChunkName: "component---src-pages-newsroom-medienmitteilungen-tsx" */),
  "component---src-pages-newsroom-medienspiegel-tsx": () => import("./../../../src/pages/newsroom/medienspiegel.tsx" /* webpackChunkName: "component---src-pages-newsroom-medienspiegel-tsx" */),
  "component---src-pages-newsroom-personelles-tsx": () => import("./../../../src/pages/newsroom/personelles.tsx" /* webpackChunkName: "component---src-pages-newsroom-personelles-tsx" */),
  "component---src-pages-newsroom-social-tsx": () => import("./../../../src/pages/newsroom/social.tsx" /* webpackChunkName: "component---src-pages-newsroom-social-tsx" */),
  "component---src-pages-newsroom-tsx": () => import("./../../../src/pages/newsroom.tsx" /* webpackChunkName: "component---src-pages-newsroom-tsx" */),
  "component---src-pages-site-search-tsx": () => import("./../../../src/pages/site-search.tsx" /* webpackChunkName: "component---src-pages-site-search-tsx" */),
  "component---src-pages-sitemap-luks-tsx": () => import("./../../../src/pages/sitemap-luks.tsx" /* webpackChunkName: "component---src-pages-sitemap-luks-tsx" */),
  "component---src-templates-content-baby-tsx": () => import("./../../../src/templates/content-baby.tsx" /* webpackChunkName: "component---src-templates-content-baby-tsx" */),
  "component---src-templates-content-disease-tsx": () => import("./../../../src/templates/content-disease.tsx" /* webpackChunkName: "component---src-templates-content-disease-tsx" */),
  "component---src-templates-content-documentation-page-tsx": () => import("./../../../src/templates/content-documentation-page.tsx" /* webpackChunkName: "component---src-templates-content-documentation-page-tsx" */),
  "component---src-templates-content-event-tsx": () => import("./../../../src/templates/content-event.tsx" /* webpackChunkName: "component---src-templates-content-event-tsx" */),
  "component---src-templates-content-kitchen-sink-tsx": () => import("./../../../src/templates/content-kitchen-sink.tsx" /* webpackChunkName: "component---src-templates-content-kitchen-sink-tsx" */),
  "component---src-templates-content-landing-page-tsx": () => import("./../../../src/templates/content-landing-page.tsx" /* webpackChunkName: "component---src-templates-content-landing-page-tsx" */),
  "component---src-templates-content-location-contact-tsx": () => import("./../../../src/templates/content-location-contact.tsx" /* webpackChunkName: "component---src-templates-content-location-contact-tsx" */),
  "component---src-templates-content-location-news-tsx": () => import("./../../../src/templates/content-location-news.tsx" /* webpackChunkName: "component---src-templates-content-location-news-tsx" */),
  "component---src-templates-content-location-team-tsx": () => import("./../../../src/templates/content-location-team.tsx" /* webpackChunkName: "component---src-templates-content-location-team-tsx" */),
  "component---src-templates-content-location-tsx": () => import("./../../../src/templates/content-location.tsx" /* webpackChunkName: "component---src-templates-content-location-tsx" */),
  "component---src-templates-content-news-tsx": () => import("./../../../src/templates/content-news.tsx" /* webpackChunkName: "component---src-templates-content-news-tsx" */),
  "component---src-templates-content-page-tsx": () => import("./../../../src/templates/content-page.tsx" /* webpackChunkName: "component---src-templates-content-page-tsx" */),
  "component---src-templates-content-patient-information-tsx": () => import("./../../../src/templates/content-patient-information.tsx" /* webpackChunkName: "component---src-templates-content-patient-information-tsx" */),
  "component---src-templates-content-specialist-tsx": () => import("./../../../src/templates/content-specialist.tsx" /* webpackChunkName: "component---src-templates-content-specialist-tsx" */),
  "component---src-templates-content-team-tsx": () => import("./../../../src/templates/content-team.tsx" /* webpackChunkName: "component---src-templates-content-team-tsx" */),
  "component---src-templates-csr-tsx": () => import("./../../../src/templates/csr.tsx" /* webpackChunkName: "component---src-templates-csr-tsx" */),
  "component---src-templates-ds-loop-tsx": () => import("./../../../src/templates/ds-loop.tsx" /* webpackChunkName: "component---src-templates-ds-loop-tsx" */),
  "component---src-templates-ds-slide-tsx": () => import("./../../../src/templates/ds-slide.tsx" /* webpackChunkName: "component---src-templates-ds-slide-tsx" */)
}

